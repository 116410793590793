/** @format */

.onboardingagreement-footer-agreement {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: var(--color-neutral-neutral-200);
}

.onboardingagreement-footer-body {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.onboardingagreement-footer-body input[type='checkbox'] {
  border: 1px solid var(--color-primary-pink);
}

.onboardingagreement-footer-button {
  margin-left: 80px;
}

.onboardingagreement-body {
  margin-bottom: 100px;
}

@media screen and (max-width: 919px) {
  .onboardingagreement-footer-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .onboardingagreement-footer-button {
    margin-left: 20px;
    margin-right: 20px;
  }

  .onboardingagreement-footer-spacing {
    margin-bottom: 10px;
  }
}
