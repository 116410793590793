/** @format */

.imagelist-view .content-wrapper {
  max-width: 1240px;
  margin: auto;
}

.imagelist-view .icon-go-back {
  margin: 50px 0 50px;
}

.object-listing-image-container {
  display: flex;
  margin: 0 auto 45px;
  position: relative;
}

.object-listing-primary-image-container {
  flex: 1;
  border-radius: 24px;
  margin-right: 5px;
}

.object-listing-secondary-image-container {
  flex: 1;
  width: 568px;
  border-radius: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-between;
}

.image-primary-image {
  width: 100%;
  aspect-ratio: 21/9;
  border-radius: 24px !important;
}

.sticky-box {
  background-color: var(--color-neutral-neutral-100);
  z-index: 999;
}

.object-listing-button-item {
  margin: 0 15px 0 0;
}

.image-secondary-image {
  width: 268px;
  height: 206px;
  border: none;
  border-radius: 24px !important;
}

/*overlay*/

.image-overlay-container {
  position: relative;
  display: inline-block;
}

.image-overlay-container .image-overlay {
  border-radius: 24px;
  position: absolute;
  top: 1%;
  left: 1%;
  width: 98%;
  height: 97%;
  background: var(--color-neutral-dark-900);
  opacity: 73%;
  transition: opacity 500ms ease-in-out;
}

.image-overlay-container:hover .image-overlay {
  opacity: 90%;
}

.image-overlay span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-neutral-neutral-100);
}

/* header */

.object-listing-header {
  display: flex;
  margin: 40px auto 20px;
  height: 120px;
}

.property-header-container {
  display: flex;
  height: 120px;
  align-items: center;
  margin: auto;
}

.property-header {
  display: flex;
  width: 100%;
  height: 120px;
  align-items: center;
}

.object-listing-header-left {
  display: flex;
  flex-direction: column;
  flex: 4;
}

.object-listing-header .flag {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100px;
}

.object-listing-header-left .object-listing-header-bottom {
  width: 60%;
}

.object-listing-header-right {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.object-listing-header-right .contact-icons-container {
  margin-top: 14px;
}

.object-listing-header-top {
  flex: 1;
}

.object-listing-header-top h3 {
  line-height: unset;
}

.object-listing-header-top h4 {
  font-family: var(--font-inter);
  font-weight: 500;
  white-space: nowrap;
}

.object-listing-header-top a {
  color: var(--color-neutral-dark-900);
}

.object-listing-header-bottom {
  flex: 1;
  display: flex;
}

.object-listing-header-icon {
  flex: 1;
}

.object-listing-header-address {
  flex: 15;
  align-self: center;
  align-self: flex-start;
}

.object-listing-header-bottom .contact-icons-container-left,
.object-listing-header-bottom .contact-icons-container-right {
  flex: 1;
  height: 42px;
}

.object-listing-header-icon .icon-location {
  margin-top: -3px !important;
}

.icon-strip-container {
  flex-direction: column;
}

.imagelist-view .icon-strip-container,
.imagelist-view-mobile .icon-strip-container {
  flex-direction: column;
  height: 200px;
  width: auto;
  margin-top: 30px;
}

.imagelist-view .icon-strip-container .icon-strip-box,
.imagelist-view-mobile .icon-strip-container .icon-strip-box {
  height: 50px;
}

.imagelist-view .icon-strip-container .icon-strip-box .icon-strip-text,
.imagelist-view-mobile .icon-strip-container .icon-strip-box .icon-strip-text {
  height: 50px;
  flex: 28;
  font-weight: 400;
}

.imagelist-view .content-wrapper .nav-link {
  max-width: 1200px;
  color: var(--color-neutral-dark-600);
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.imagelist-view .content-wrapper .nav-link.active {
  border-left-color: var(--color-neutral-neutral-100);
  border-right-color: var(--color-neutral-neutral-100);
  border-top-color: var(--color-neutral-neutral-100);
  color: var(--color-neutral-dark-900);
  font-weight: 500;
  border-bottom: 3px solid var(--color-neutral-dark-900) !important;
}

.imagelist-view .content-wrapper .nav-link:hover {
  border-left-color: var(--color-neutral-neutral-100);
  border-right-color: var(--color-neutral-neutral-100);
  border-top-color: var(--color-neutral-neutral-100);
  color: var(--color-neutral-dark-700);
  border-bottom: 3px solid var(--color-neutral-dark-700) !important;
}

.imagelist-view .tab-content {
  margin-bottom: 100px;
}

.imagelist-view-all-image-container {
  width: 1040px;
  margin: auto;
}

.imagelist-view-all-image-primary-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.imagelist-view-all-image-primary-image-container .image-primary-image {
  max-width: 100%;
  width: auto;
  height: 605px;
}

.imagelist-view-all-image-secondary-image-container {
  width: 100%;
  margin: auto auto 100px;
  display: flex;
  flex-wrap: wrap;
}

.imagelist-view-all-image-secondary-image-container .image-secondary-image {
  width: auto;
  max-width: 500px;
  height: 300px;
  margin: 9px;
  flex: 1;
}

.imagelist-view .icon-strip-icon {
  background-position: left;
}

.object-listing-headling-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-neutral-dark-800);
  margin-bottom: 20px;
}

.object-listing-headling-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-neutral-dark-600);
  word-wrap: break-word;
}

.object-listing-accordion-header-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-primary-pink);
}

.object-bidding.in-progress-flag {
  margin-left: 30px;
  border-radius: 10px;
  width: 60%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 5px;
}

.object-bidding.in-progress-flag span {
  margin-left: 5%;
}

.object-bidding.in-progress-flag .alert {
  padding-top: 2px;
  padding-bottom: 2px;
  border: 1px solid var(--color-primary-pink);
  margin-bottom: 0px;
}

.object-listing-accordion-header .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:svgjs='http://svgjs.com/svgjs' xmlns:xlink='http://www.w3.org/1999/xlink' width='288' height='288'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='288' height='288' fill='%230c63e4' viewBox='0 0 16 16'%3E%3Cpath fill='%23cf6584' fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' class='color000 svgShape'/%3E%3C/svg%3E%3C/svg%3E");
}

.object-listing-accordion-header .accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:svgjs='http://svgjs.com/svgjs' xmlns:xlink='http://www.w3.org/1999/xlink' width='288' height='288'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='288' height='288' fill='%230c63e4' viewBox='0 0 16 16'%3E%3Cpath fill='%23cf6584' fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' class='color000 svgShape'/%3E%3C/svg%3E%3C/svg%3E");
}

.object-listing-accordian-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid var(--color-neutral-dark-500);
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 16px;
  width: 100%;
}

.object-listing-accordian-list-no-border {
  border: none;
  margin-bottom: 0;
}

.object-listing-accordian-list-left {
  width: 50%;
  color: var(--color-neutral-dark-700);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-right: 15px;
}

.object-listing-accordian-list-right {
  width: 50%;
  color: var(--color-neutral-dark-600);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.object-listing-accordian-list-right .list-of-rooms {
  font-weight: 400;
}

.list-of-rooms-wrapper div {
  color: var(--color-neutral-dark-600);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.assessments {
  color: var(--color-neutral-dark-600);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
}
.assessment {
  width: 100%;
  border-top: 1px solid #aaaaaa;
  padding-top: 10px;
  margin-top: 10px;
}

.assessments .object-listing-accordian-list {
  border: none;
}
.assessments ul {
  border-bottom: 1px solid #aaaaaa;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.assessments ul:last-child {
  border-bottom: none;
}
.assessments ul li {
  color: var(--color-neutral-dark-600);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.object-listing-accordian-list-full {
  width: 100%;
  color: var(--color-neutral-dark-600);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
}

.object-listing-accordian-list-left-viewings {
  width: 100%;
  color: var(--color-neutral-dark-700);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.object-listing-accordian-viewings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.object-listing-viewings-button {
  width: 100%;
  margin: 8px 0;
  text-align: center;
}

.object-listing-contact-card-image {
  width: 100px;
  height: 100px;
  border-radius: 10%;
  margin-right: 16px;
}

.object-listing-contact-card-image-clickable {
  cursor: pointer;
}

.object-listing-contact-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid var(--color-neutral-dark-500);
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 16px;
  background-color: var(--color-neutral-dark-800);
}

.object-listing-contact-card-content {
  width: 100%;
  display: flex;
}

.object-listing-contact-card-content-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.object-listing-contact-card-content-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.object-listing-contact-card-content-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.object-listing-contact-card-content-details-left {
  align-items: flex-start;
}

.object-listing-contact-card-content-details-right {
  align-items: flex-end;
  justify-content: space-evenly;
}

.object-listing-contact-card-content-name {
  width: 100%;
  color: var(--color-neutral-neutral-100);
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.object-listing-contact-card-content-job {
  width: 100%;
  color: var(--color-neutral-dark-500);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.object-listing-contact-card-content-buttons {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  /* flex-direction: column; */
}

.object-listing-contact-card-content-buttons-top {
  display: flex;
  flex-direction: row;
}

.object-listing-contact-card-content-button {
  width: fit-content;
  margin: 8px 2px;
  text-align: center;
  border-radius: 24px;
}

.object-listing-contact-card-content-button:focus-within {
  opacity: 1;
}

.object-listing-contact-card-content-sq-button {
  width: 100%;
  margin: 8px 2px;
  text-align: center;
  border-radius: 8px;
}

.object-listing-image {
  width: 100%;
  margin-top: 1rem;
  aspect-ratio: 4/3;
  border-radius: 8px !important;
}

.object-listing-sales-headline {
  margin: auto;
}

.object-listing-facts {
  margin-top: 2rem;
}

.object-listing-map {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.document-download {
  color: var(--color-primary-pink);
}
#images .col-md-6 {
  position: relative;
}
#floorPlan .col-md-6 {
  position: relative;
}
.image-overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  color: var(--color-neutral-dark-700);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
  padding: 20px 40px;
}

.bidding-modal.bidding-hidden-text {
  padding-top: 20px;
}

.bidding-modal.bidding-hidden-text span {
  margin-left: 10px;
  font-weight: normal;
}

.object-listing-image-bidding-indicator-wrapper {
  position: absolute;
  top: 20px;
  left: 15px;
}

.object-listing-image-bidding-indicator-wrapper .btn {
  border: none;
}

.object-listing-image-bidding-indicator-wrapper.mobile {
  position: relative;
  top: 10px;
  left: auto;
  display: flex;
  justify-content: center;
}

.object-listing-image-bidding-indicator-wrapper.mobile .btn {
  height: auto;
  padding: 5px;
  width: 50%;
}

@keyframes bid-in-progress {
  0% {
    background-color: var(--color-neutral-neutral-200);
    color: var(--color-primary-pink) !important;
  }
  50% {
    background-color: var(--color-primary-pink);
    color: var(--color-neutral-neutral-100) !important;
  }
  100% {
    background-color: var(--color-neutral-neutral-200);
    color: var(--color-primary-pink) !important;
  }
}

.object-bidding-indicator {
  cursor: auto;
  pointer-events: none;
  border-radius: 16px !important;
}

.object-bidding-indicator.animate {
  animation-name: bid-in-progress;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.object-listing-header-bottom .flag {
  display: none;
}

.object-listing-header-price-currency .object-listing-header-calculated-price {
  color: var(--color-neutral-dark-600);
}

@media (min-width: 920px) {
  .imagelist-view {
    display: contents;
  }

  .imagelist-view-mobile {
    display: none;
  }

  .object-listing-image-slider-mobile {
    display: none;
  }

  .object-listing-image-bidding-indicator-wrapper.mobile {
    display: none;
  }
}

@media (max-width: 919px) {
  .imagelist-view {
    display: none;
  }

  .object-listing-image-container {
    display: none;
  }

  /* .imagelist-view-mobile {
    display: contents;
    max-width: 414px;
  } */

  .imagelist-view-mobile .image-primary-image {
    width: 100%;
    height: 250px;
  }

  .object-listing-image-slider-mobile {
    display: block;
    margin: auto;
    width: 350px;
  }

  .imagelist-view-mobile .contact-icons-container {
    width: unset;
  }

  .imagelist-view-mobile .contact-icons-container .icon-phone-pink {
    background-position: left;
  }

  .object-listing-image-slider-mobile div {
    border-radius: 16px !important;
    background-color: var(--color-neutral-neutral-100);
  }

  .imagelist-view-mobile .object-listing-header {
    width: 100%;
    padding: 0px 21px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .imagelist-view-mobile .object-listing-header .object-listing-header-right {
    align-self: flex-start;
    margin-top: 12px;
  }

  .imagelist-view-mobile h3 {
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0em;
    text-align: left;
  }

  .imagelist-view-mobile .object-listing-header-address {
    align-self: unset;
    font-size: 12px;
  }

  .imagelist-view-mobile .object-listing-header-price {
    align-self: unset;
    font-size: 16px;
  }

  .imagelist-view-mobile .object-listing-header-top {
    flex: 1 1;
  }

  .imagelist-view-mobile .object-listing-header-bottom {
    flex: 1 1;
  }

  .object-listing-header {
    height: 100%;
  }

  .imagelist-view-mobile .property-header-container {
    width: 100%;
    padding: 15px;
    flex-direction: column;
    margin-bottom: 5px;
    background: rgb(207, 101, 132, 0.1);
    height: auto;
  }

  .imagelist-view-mobile .property-header-container .property-header {
    align-self: flex-start;
    margin-top: 12px;
    height: auto;
  }

  .imagelist-view-mobile .object-listing-button-item {
    width: fit-content;
    min-width: 0px;
    margin: 8px 2px;
    padding: 16px;
  }

  .imagelist-view-mobile .object-listing-sales-headline {
    margin-top: 2rem;
    padding-left: 21px;
  }

  .imagelist-view-mobile .object-listing-accordian-list {
    display: flex;
    flex-direction: column;
  }

  .imagelist-view-mobile .object-listing-accordian-list-left {
    width: 100%;
  }

  .imagelist-view-mobile .object-listing-accordian-list-right {
    width: 100%;
  }

  .imagelist-view-mobile .object-listing-contact-card-content {
    display: flex;
    flex-direction: column;
  }

  .imagelist-view-mobile .object-listing-contact-card-content-container {
    display: flex;
    flex-direction: column;
  }

  .imagelist-view-mobile .object-listing-contact-card-content-details {
    align-items: flex-start;
  }

  .imagelist-view-mobile .right {
    display: flex;
    flex-direction: row;
  }

  .imagelist-view-mobile .object-listing-headling-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .imagelist-view-mobile .object-listing-accordion-header-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .imagelist-view-mobile .content-wrapper .nav-link {
    max-width: 1200px;
    color: var(--color-neutral-dark-600);
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
  }

  .imagelist-view-mobile .object-listing-header-right {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 0 4px;
  }

  .imagelist-view-mobile .content-wrapper .nav-link.active {
    border-left-color: var(--color-neutral-neutral-100);
    border-right-color: var(--color-neutral-neutral-100);
    border-top-color: var(--color-neutral-neutral-100);
    color: var(--color-neutral-dark-900);
    font-weight: 500;
    border-bottom: 3px solid var(--color-neutral-dark-900) !important;
  }

  .imagelist-view-mobile .content-wrapper .nav-link:hover {
    border-left-color: var(--color-neutral-neutral-100);
    border-right-color: var(--color-neutral-neutral-100);
    border-top-color: var(--color-neutral-neutral-100);
    color: var(--color-neutral-dark-700);
    border-bottom: 3px solid var(--color-neutral-dark-700) !important;
  }

  .imagelist-view-mobile .icon-strip-container {
    flex-direction: column;
    height: 200px;
    width: auto;
  }

  .imagelist-view-mobile .icon-strip-container .icon-strip-box {
    height: 50px;
  }

  .imagelist-view-mobile
    .icon-strip-container
    .icon-strip-box
    .icon-strip-text {
    height: 50px;
    flex: 11 1;
    font-weight: 400;
  }

  .object-listing-header-left .object-listing-header-bottom {
    width: unset;
  }

  .object-listing-header-top,
  .object-listing-header-top span {
    font-size: 12px;
  }

  .imagelist-view-mobile .tab-content {
    margin: auto 20px 50px;
  }

  .object-listing-contact-card-content-details-right {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
  }
}

@media screen and (max-width: 919px) {
  .object-listing-header .flag {
    display: none;
  }

  .object-listing-header-bottom .flag {
    display: flex;
    flex: 2;
  }
}
